import * as React from "react";
import { useTranslations } from "../../../context/fancy-context";
import {
	DataObserveKey,
	VisualComponent,
	FocusableComponent,
} from "../../../types/fancy-base-attributes";
import { cn, f2u } from "../../../utils/shorthands";
import { SrOnly } from "../../text/sr-only/sr-only";
import * as scss from "./link.scss";

export interface LinkType {
	/** Url of the page the link redirects to */
	href: string;
	/** Opens the link in a new tab if set to true */
	openNew?: boolean;
	/** Downloads the linked resource (instead of displaying it in the browser) if set to true. Provide a string to specify the default file name. */
	download?: boolean | string;
}

export type LinkContainerProps = LinkType & {
	/** Label for the link */
	children: React.ReactNode;
	/** Callback for onClick events */
	onClick?: React.MouseEventHandler<HTMLAnchorElement>;
	/** Renders the link inline if set to true, should be used when the link is part of a text block */
	inlineInText?: boolean;
	/** Screen reader text for the link */
	"aria-label"?: string;
} & DataObserveKey &
	VisualComponent &
	FocusableComponent;

export type LinkTextProps = {
	children: React.ReactNode;
	/** Renders the link without style if set to true */
	unstyled?: boolean;
	/** Size of the link, defaults to `"medium"` */
	size?: "small" | "medium" | "large";
	/** When link is wrapped into single words */
	disableWordBreak?: boolean;
} & VisualComponent;

export type LinkProps = LinkContainerProps & LinkTextProps;

export function Link(props: LinkProps): JSX.Element {
	const { children, unstyled, size = "medium", disableWordBreak, ...rest } = props;

	return (
		<LinkContainer {...rest}>
			<LinkText unstyled={unstyled} size={size} disableWordBreak={disableWordBreak}>
				{children}
			</LinkText>
		</LinkContainer>
	);
}

export function LinkContainer(props: LinkContainerProps): JSX.Element {
	const {
		href,
		openNew,
		download,
		onClick,
		children,
		inlineInText,
		className,
		style,
		onBlur,
		onFocus,
		onKeyDown,
		onMouseDown,
		tabIndex,
		onMouseEnter,
		onMouseLeave,
		...rest
	} = props;

	const i18n = useTranslations();

	return (
		<a
			data-observe-key={props["data-observe-key"]}
			data-component="link"
			href={href}
			aria-label={props["aria-label"]}
			className={cn(
				className,
				scss.linkContainer,
				inlineInText && scss.inlineLink,
				openNew && scss.openNew
			)}
			style={style}
			target={f2u(openNew && "_blank")}
			download={f2u(download)}
			rel={f2u(openNew && "noopener")}
			onBlur={onBlur}
			onFocus={onFocus}
			onKeyDown={onKeyDown}
			onMouseDown={onMouseDown}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
			onClick={onClick}
			tabIndex={tabIndex}
			{...rest}
		>
			{openNew && <SrOnly isInline={inlineInText}>{`${i18n.linkButtonOpenNewAriaLabel}:`}</SrOnly>}
			{children}
		</a>
	);
}

function LinkText(props: LinkTextProps): JSX.Element {
	const { children, size = "medium", unstyled, disableWordBreak, className, style } = props;
	return (
		<span
			className={cn(
				className,
				scss.linkText,
				unstyled && scss.unstyled,
				disableWordBreak && scss.disableWordBreak,
				scss[size]
			)}
			style={style}
		>
			{children}
			{!unstyled && (
				<span className={cn(scss.linkIndicator)} aria-hidden="true">
					&nbsp;
					<span className={scss.upArrow}>&#8599;</span>
					<span className={scss.straightArrow}>&rarr;</span>
				</span>
			)}
		</span>
	);
}

LinkContainer.LinkText = LinkText;
